import axios from "axios";

console.log(process.env.REACT_APP_API_URL)
console.log(process.env.NODE_ENV)
console.log(process.env.PUBLIC_URL)
console.log(process.env.REACT_APP_IMG_URL)

const http = axios.create({

  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-type": "application/json"
  }
});
axios.interceptors.request.use(request => {

  // Edit request config
  return request;
}, error => {
  console.log(error);
  return Promise.reject(error);
});

export default {
  auth(url = 'users') {
    return {
      login: ({ email, password }) => http.post(url + '/authenticate', {
        username: email,
        password: password
      }),
      sociallogin: ({ email }) => http.post(url + '/socialauthenticate', {
        username: email
      })

    }
  },


  user(url = 'users') {

    const config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    return {
      fetchAll: () => http.get(url + '/all', config),
      getCart: (param) => http.post(url + '/getCart', param, config),
      fetchPagination: (page, limit = 10, role = null) =>
        http.get(url + '/findPagination' + "?page=" + page + "&limit=" + limit + "&role=" + role, config),
      fetchById: id => http.get(url + "/single/" + id, config),
      create: newRecord => http.post(url + '/register', newRecord, config),
      update: (id, updatedRecord) => http.post(url + "/update/" + id, updatedRecord, config),
      updateCart: (id, updatedRecord) => http.post(url + "/updateCart/" + id, updatedRecord, config),
      delete: id => http.put(url + "/remove/" + id, {}, config),
      sendOtp: (param) => http.post(url + '/otp/send', param),
      verifyOtp: (param) => http.post(url + '/otp/verify', param),
      register: (param) => http.post(url + '/register', param),
      sendresetlink: (param) => http.post(url + '/sendresetlink/' + param),
      sendcontatform: newRecord => http.post(url + '/sendcontatform', newRecord, config),
    }
  },
 
  templates(url = 'templates') {
    const config = {
      headers: {
        'authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    return {
      fetchAll: (_userId) => http.get(url + '/all?userId='+_userId, config),
      fetchPagination: (page, limit = 10, title = null, _userId = null) =>
        http.get(url + '/findPagination' + "?page=" + page + "&limit=" + limit + "&title=" + title + "&userId=" + _userId, config),
      fetchById: id => http.get(url + "/single/" + id, config),
      create: newRecord => http.post(url + '/addnew', newRecord, config),
      update: (id, updatedRecord) => http.post(url + "/update/" + id, updatedRecord, config),
      delete: id => http.put(url + "/remove/" + id, {}, config),
      fetchRandomTemplates: (param) => http.post(url + '/getRandomTemplates', param, config),
    }
  },
  inspections(url = 'inspections') {
    const config = {
      headers: {
        'authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    return {
      fetchAll: (_userId) => http.get(url + '/all?userId='+_userId, config),
      fetchPagination: (page, limit = 10, title = null, _userId = null) =>
        http.get(url + '/findPagination' + "?page=" + page + "&limit=" + limit + "&title=" + title + "&userId=" + _userId, config),
      fetchById: id => http.get(url + "/single/" + id, config),
      create: newRecord => http.post(url + '/addnew', newRecord, config),
      update: (id, updatedRecord, _userId) => http.post(url + "/update/" + id+"?userId="+_userId, updatedRecord, config),
      delete: id => http.put(url + "/remove/" + id, {}, config),
      fetchRandomInspections: (param) => http.post(url + '/getRandomInspections', param, config),
      createPdf: (id, data) => http.post(url + "/createPdf/" + id, data, config),
    }
  },
  order(url = 'order') {

    const config = {
      headers: {
        'authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    return {
      fetchAll: () => http.get(url + '/all', config),
      fetchPagination: (page, limit = 10, filterbyID = null, isUpcoming = null) =>
        http.get(url + '/findPagination' + "?page=" + page + "&limit=" + limit + "&filterbyID=" + filterbyID + "&isUpcoming=" + isUpcoming, config),
      fetchById: id => http.get(url + "/" + id, config),
      create: newRecord => http.post(url + '/addnew', newRecord, config),
      update: (id, updatedRecord) => http.post(url + "/update/" + id, updatedRecord, config),
      delete: id => http.put(url + "/remove/" + id, {}, config),
      getMyOrders: id => http.get(url + '/getMyOrders/' + id, config),
      getAvailableTimeSlots: param => http.post(url + '/getAvailableTimeSlots', param, config),
      getCount: newRecord => http.post(url + '/getCount', newRecord, config),
      getEarnings: (page, limit = 10, commercialid = null) =>
        http.get(url + '/getEarnings' + "?page=" + page + "&limit=" + limit + "&commercialid=" + commercialid, config),
    }
  },
  utility(url = 'utility') {

    const config = {
      headers: {
        'authorization': 'Bearer ' + localStorage.getItem('token'),
        "Content-Type": "multipart/form-data",

      }
    };

    return {
      upload: (file) => http.post(url + '/upload', file, config),
      createPaymentIntent: (param) => http.post(url + '/createPaymentIntent', param, config),
    }
  },

}