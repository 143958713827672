import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import logo from '../../images/logo.png'
import { Link, useNavigate, useLocation } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import './style.css'
import Form from 'react-bootstrap/Form';
import { IoCloseOutline, IoLogoFacebook, IoLogoGoogle } from "react-icons/io5";
import * as user from "../../actions/user";
import { connect } from "react-redux";
import API from '../../utils/api';
import Swal from "sweetalert2";
import Reaptcha from 'reaptcha';
import Dropdown from 'react-bootstrap/Dropdown';
import Image from 'react-bootstrap/Image'
import userImage from '../../images/user.jpg'
import SocialButton from "./SocialButton";
import countries from "./countries"
import { GoogleLogin } from '@react-oauth/google';
import jwtDecode from 'jwt-decode'
import { useGoogleLogin } from '@react-oauth/google';
import { hasGrantedAllScopesGoogle, hasGrantedAnyScopeGoogle } from '@react-oauth/google';
import axios from "axios";

function Header(props) {

  const { logout, getAuthUser, login, sociallogin, isAuthenticated } = useContext(AuthContext)
  const authUser = getAuthUser()
  const navigate = useNavigate()
  let location = useLocation();

  const [role, setRole] = useState('residential');
  const [approved, setApproved] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [birthday, setBirthday] = useState(new Date());
  const [subjects, setSubjects] = useState('');
  const [experience, setExperience] = useState('');
  const [password, setPassword] = useState('');
  const [confirmpassword, setConfirmpassword] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [termscheckbox, setTermscheckbox] = useState(false);
  const [isresetpwd, setIsresetpwd] = useState(false);
  const [navcollapse, setNavcollapse] = useState(false);
  const [provider, setProvider] = useState('');
  const [whichIssueFoundInOldSystem, setWhichIssueFoundInOldSystem] = useState("");
  const [whichFeatureAppealsInOldSystem, setWhichFeatureAppealsInOldSystem] = useState("");

  var [emailValue, setEmailValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  const [isScrolled, setIsScrolled] = useState(false);
  var [loading, setLoading] = useState(false);
  var [errorMessage, setErrorMessage] = useState(null);
  const [showLogin, setShowLogin] = useState(false);
  const [showRegistration, setShowRegistration] = useState(false);
  const loginHide = () => props.invokesignin(false);
  const loginShow = () => {
    setIsresetpwd(false); props.invokesignin(true); setErrorMessage(null);

  }
  const registrationHide = () => setShowRegistration(false);
  const registrationShow = () => {

    setShowRegistration(true);
  }


  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const isMobileView = window.innerWidth < 992;

      if (isMobileView) {
        setIsScrolled(scrollTop > 45);
      } else {
        if (scrollTop > 45) {
          setIsScrolled(true);
          document.querySelector('.fixed-top').classList.add('bg-white', 'shadow');
          document.querySelector('.fixed-top').style.top = '-45px';
        } else {
          setIsScrolled(false);
          document.querySelector('.fixed-top').classList.remove('bg-white', 'shadow');
          document.querySelector('.fixed-top').style.top = '0';
          // document.querySelector('.fixed-top').style.zIndex = '3000';
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', () => {
      setIsMobile(window.innerWidth < 992);
    });
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', () => {
        setIsMobile(window.innerWidth < 992);
      });
    };

  }, [])


  const isEmailValid = () => {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(email);
  }
  const register = () => {
    setLoading(true);
    /////NO UI for this below??? do we required this
    // if (getAge(birthday) < 15) {
    //   Swal.fire("Oops!", "You have to be over 14 years old.", 'warning')
    //   setLoading(false);
    //   return;
    // }
    if (password !== confirmpassword) {
      Swal.fire("Oops!", "Password does not match.", 'warning')
      setLoading(false);
      return;
    }

    if (!termscheckbox) {
      Swal.fire("Oops!", "I accept the general conditions and privacy policy", 'warning')
      setLoading(false);
      return;
    }

    // if (!captcha) {
    //   Swal.fire("Oops!", "completar la verificación de captcha", 'warning')
    // setLoading(false);
    //   return;
    // }


    API.user().register({
      firstname: firstname,
      lastname: lastname,
      email: email,
      birthday: birthday,
      password: confirmpassword,
      role: role,
      image: 'uploads/user.jpg',
      mobile: '',
      subjects: subjects,
      experience: experience,
      approved: role === 'residential' ? false : false,
      verified: true,
      provider: "generic",
      address: address,
      whichIssueFoundInOldSystem:whichIssueFoundInOldSystem,
      whichFeatureAppealsInOldSystem:whichFeatureAppealsInOldSystem
    })
      .then(function (response) {

        if (response.status) {
          setLoading(false)
          console.log(response.data)
          setShowRegistration(false)
         // Swal.fire("", "A verification email has been sent. Verify your email.", 'success')
          Swal.fire({
            title: "Yes!",
            text: "Thank you for joining the Solarspect waiting list. We limit our inspections to 10 new users per month. When you’ve been chosen as one of the lucky ones, you will receive an email to let you know that your free membership has begun! Fingers crossed, you’ll be onboard soon!!!",

            confirmButtonText: "Ok",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
             // loginShow()
            } 
          });
          setRole('residential')
          setApproved(false)
          setFirstname('')
          setLastname("")
          setEmail("")
          setSubjects("")
          setExperience("")
          setPassword("")
          setConfirmpassword("")
          setCaptcha("")
          setTermscheckbox(false)

        }
      })
      .catch(function (error) {
        setLoading(false)
        console.log(error.response.data.message)
        Swal.fire("Oops!", error.response.data.message, 'warning')

      });

  }

  function onChangeCaptcha(value) {
    setCaptcha(value)
  }

  const handleLogin = () => {
    login(
      emailValue,
      passwordValue,
      navigate,
      setLoading,
      setErrorMessage
    )
    loginHide()
  }


  const sendResetPwdLink = () => {
    API.user().sendresetlink(emailValue)
      .then(function (res) {
        if (res.status === 200) {

          loginHide()
          Swal.fire("", "Password reset link has been sent to your registered email.", 'success')
        }

      }).catch(err => console.log(err))

  }

  const signout = () => {
    logout(navigate)
    // Swal.fire({
    //     icon: 'success',
    //     title: 'Logout Sucessfull',
    //     text: 'Thank You'
    // })
    // dispatch({ type: "user/logout" })
    // history.push("/");
  }

  useEffect(() => {
    if (errorMessage === 'success') {
      props.invokesignin(false)
      setErrorMessage(null)

    }
  }, [errorMessage]);

  const handleSocialLogin = (user) => {

    sociallogin(
      user._profile.email,
      navigate,
      setLoading,
      setErrorMessage
    )
  };
  const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};

  const getAge = birthDate => Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e+10)

  const handleSocialRegister = (user) => {
    console.log("scoial success---->", user._profile);
    // setFirstname(user._profile.firstName)
    // setLastname(user._profile.lastName)
    // setEmail(user._profile.email)
    // setProvider(user._provider)
    // setRole('residential')
    // setConfirmpassword(user._token.accessToken)
    // setPassword(user._token.accessToken)


    API.user().register({
      firstname: user._profile.firstName,
      lastname: user._profile.lastName,
      email: user._profile.email,
      birthday: birthday,
      password: user._token.accessToken,
      role: 'residential',
      image: 'uploads/user.jpg',
      mobile: '',
      subjects: subjects,
      experience: experience,
      verified: true,
      approved: false,
      provider: user._provider,
      address: address,
    })
      .then(function (response) {

        if (response.status) {

          setShowRegistration(false)
          sociallogin(
            response.data.email,
            navigate,
            setLoading,
            setErrorMessage
          )

          setRole('residential')
          setApproved(false)
          setFirstname('')
          setLastname("")
          setEmail("")
          setSubjects("")
          setExperience("")
          setPassword("")
          setConfirmpassword("")
          setCaptcha("")
          setTermscheckbox(false)
          setAddress('')
          setWhichIssueFoundInOldSystem("")
          setWhichFeatureAppealsInOldSystem("")
        }
      })
      .catch(function (error) {
        console.log(error.response.data.message)
        Swal.fire("Oops!", error.response.data.message, 'warning')

      });
    //facebook
    // {
    //   "_provider": "facebook",
    //   "_profile": {
    //       "id": "10225807334828315",
    //       "name": "Chetan N",
    //       "firstName": "Chetan",
    //       "lastName": "N",
    //       "email": "co5296@gmail.com",
    //       "profilePicURL": "https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=10225807334828315&height=50&width=50&ext=1675528607&hash=AeRMeNsWG6xf_ac6ti4"
    //   },
    //   "_token": {
    //       "accessToken": "EAAMNvOfgJvQBALUpU29CMjj4Gn3DqXJf7KMZCWK2ExHhc73CHytxeZBMXxxMjclkw6Rjmp7MKmHoZClV9crO30JwQamqtVMgRloupDRECKthwmIa1BjRGwEZComZCBWA4ZAkG5qkG4OIeIdZAz9zt8jZAHp3IZCqi05ZBveAQiYTcgYSiy91moJMJGGZBeJ2dGsHtO23YaJe0iOGgZDZD",
    //       "expiresAt": 1672941601770
    //   }
    //}

    //google
    //   {
    //     "_provider": "google",
    //     "_profile": {
    //         "id": "112473292017237932816",
    //         "name": "chetan nikam",
    //         "firstName": "chetan",
    //         "lastName": "nikam",
    //         "email": "iamchetan03@gmail.com",
    //         "profilePicURL": "https://lh3.googleusercontent.com/a/AEdFTp5NxSo46GLvDhQOx2symJ2M68yRZQeqgli4_eTsew=s96-c"
    //     },
    //     "_token": {
    //         "accessToken": "ya29.a0AX9GBdVGGYaOUrUy6_pFDMf0q-4bckzBp4SL4F6Y6U-Mup_nT_21JKvMDQZaJr0R-BwuLmApCXw1SSb83WPr2Wf89zTMTq74C2h_9u6P3bRUvcaDPU3NzaJJBp5aqoybxUY4K5PNFgVSXxKIQgKAwg20FtKVaCgYKAdMSARMSFQHUCsbCcSJ4yqSXW-N9RcgdWZPM_w0163",
    //         "idToken": "eyJhbGciOiJSUzI1NiIsImtpZCI6IjhlMGFjZjg5MWUwOTAwOTFlZjFhNWU3ZTY0YmFiMjgwZmQxNDQ3ZmEiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiMjg5MjI4OTgxMTgtcWI3Nm9nMTN0aWM3OXAwNnIwOXBqcWJ2ZG1hOGtoN3IuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJhdWQiOiIyODkyMjg5ODExOC1xYjc2b2cxM3RpYzc5cDA2cjA5cGpxYnZkbWE4a2g3ci5hcHBzLmdvb2dsZXVzZXJjb250ZW50LmNvbSIsInN1YiI6IjExMjQ3MzI5MjAxNzIzNzkzMjgxNiIsImVtYWlsIjoiaWFtY2hldGFuMDNAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImF0X2hhc2giOiI2Tl80aElkLXc1cHVZOXhYbHNJVU1BIiwibmFtZSI6ImNoZXRhbiBuaWthbSIsInBpY3R1cmUiOiJodHRwczovL2xoMy5nb29nbGV1c2VyY29udGVudC5jb20vYS9BRWRGVHA1TnhTbzQ2R0x2RGhRT3gyc3ltSjJNNjh5UlpRZXFnbGk0X2VUc2V3PXM5Ni1jIiwiZ2l2ZW5fbmFtZSI6ImNoZXRhbiIsImZhbWlseV9uYW1lIjoibmlrYW0iLCJsb2NhbGUiOiJlbiIsImlhdCI6MTY3MjkzODIxMCwiZXhwIjoxNjcyOTQxODEwLCJqdGkiOiJhMmViM2Q1MzIxYWU2YjU4NWI1ZDIyNzVlNTMwMDUxZTFhMjNjOTBmIn0.mlZbhTX6C0gHcSIKWuZK09Nau4yCgK1s4ovrGcxhVHlL7-IilRgCCIWznNU0PmcIi6AE84sZiHS07n15cQxLM1eNMV2AWjjtmbEYo2BAzJXsGSBX0hpezGFmP9tNtTKGG0VIkAva0kQQ37rL_H3yuJOMcbjnKWiVoPKu0YsS0us5ylAdX1EiMVypWqtCFRkg4E61zBEoOrkmiMjkCLZF0Ufmn8lOj_Y7BGXURo37HQwRfBDGZacMSxHQR_AWlzHE_nzMd9vCdQFPWvi56mhTV04zuBDTCDaw7K5K9MArsthzljgbY8i_flQf3NlhdMinRxxRrPzLY5pM_0KkFk1_Og",
    //         "scope": "email profile openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
    //         "expiresIn": 3599,
    //         "firstIssued_at": 1672938210859,
    //         "expiresAt": 1672941809859
    //     }
    // }
  };
  const handleSocialLoginFailure = (err) => {
    console.error("scoial error---->", err);
  };

  const googlelogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse);
      const userInfo = await axios.get(
        'https://www.googleapis.com/oauth2/v3/userinfo?access_token=' + tokenResponse.access_token,

      );

      console.log(userInfo);
      sociallogin(
        userInfo.data.email,
        navigate,
        setLoading,
        setErrorMessage
      )
    },

  });
  return (
    <>
      <div className={`container-fluid ${location?.pathname?.includes('mobile-') ? "mobilehide" : ""}`} >
        <div className="fixed-top px-0 wow fadeIn" data-wow-delay="0.1s">
          <div className="top-bar row gx-0 align-items-center d-none d-lg-flex">
            <div className="col-lg-6 px-5 text-start">
              <small><i className="fa fa-envelope text-primary me-2"></i>support@solarspect.io</small>
              {/* <small className="ms-4"><i className="fa fa-phone-alt text-primary me-2"></i>+012 345 6789</small> */}

            </div>
            <div className="col-lg-6 px-5">
              <div className="d-none d-lg-flex ms-2 float-end">
                <a className="btn btn-light btn-sm-square rounded-circle ms-3" href="https://www.facebook.com/solarspect.crm" target="_blank">
                  <small className="fab fa-facebook-f text-primary"></small>
                </a>
                {/* <a className="btn btn-light btn-sm-square rounded-circle ms-3" href="">
                  <small className="fab fa-twitter text-primary"></small>
                </a>
                <a className="btn btn-light btn-sm-square rounded-circle ms-3" href="">
                  <small className="fab fa-linkedin-in text-primary"></small>
                </a> */}
              </div>
            </div>
          </div>

          <nav className="navbar navbar-expand-lg navbar-light py-lg-0 px-lg-5 wow fadeIn" data-wow-delay="0.1s">
            <Link to="/" className="navbar-brand ms-4 ms-lg-0">
              <h1 className="display-5 text-primary m-0"><img className="logo" src={logo} /></h1>
            </Link>
            <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <div className="navbar-nav ms-auto p-4 p-lg-0" >
                {!authUser ? <>
                  <Link className={location?.pathname === "/" ? "nav-item nav-link active" : "nav-item nav-link"} to="/" onClick={scrollToTop}><span data-bs-toggle="collapse" data-bs-target="#navbarCollapse">Home</span></Link>
                  <Link className={location?.pathname === "/about" ? "nav-item nav-link active" : "nav-item nav-link"} to="/about" onClick={scrollToTop}><span data-bs-toggle="collapse" data-bs-target="#navbarCollapse">About</span></Link>
                  {/* <Link className={location?.pathname === "/pricing" ? "nav-item nav-link active" : "nav-item nav-link"} to="/pricing" onClick={scrollToTop}><span data-bs-toggle="collapse" data-bs-target="#navbarCollapse">Pricing</span></Link> */}
                  <Link className={location?.pathname === "/contact" ? "nav-item nav-link active" : "nav-item nav-link"} to="/contact" onClick={scrollToTop}><span data-bs-toggle="collapse" data-bs-target="#navbarCollapse">Contact</span></Link>
                  <Link className="nav-item nav-link" onClick={registrationShow} ><span data-bs-toggle="collapse" data-bs-target="#navbarCollapse">Join The Waiting List</span></Link>
                  <Link className="nav-link" onClick={loginShow}><span data-bs-toggle="collapse" data-bs-target="#navbarCollapse">Login</span></Link>

                </>
                  :
                  <>
                    <Link className={location?.pathname === "/" + authUser?.role + "/dashboard" ? "nav-item nav-link active" : "nav-item nav-link"} to={"/" + authUser?.role + "/dashboard"} onClick={scrollToTop}><span data-bs-toggle="collapse" data-bs-target="#navbarCollapse">Dashboard</span></Link>

                    {/* {authUser?.role  !== 'residential' &&  <Link className={location?.pathname === "/" + authUser?.role + "/templates" ? "nav-item nav-link active" : "nav-item nav-link"} to={"/" + authUser?.role + "/templates"} onClick={scrollToTop}><span data-bs-toggle="collapse" data-bs-target="#navbarCollapse">Templates</span></Link>} */}
                    {authUser?.role  == 'admin' &&  <Link className={location?.pathname === "/" + authUser?.role + "/templates" ? "nav-item nav-link active" : "nav-item nav-link"} to={"/" + authUser?.role + "/templates"} onClick={scrollToTop}><span data-bs-toggle="collapse" data-bs-target="#navbarCollapse">Templates</span></Link>}
                    {/* <Link className={location?.pathname === "/"+authUser?.role+"/templates-view" ? "nav-item nav-link active" : "nav-item nav-link"} to={"/"+authUser?.role+"/templates-view"} >Templates-View</Link> */}
                    {/* <Link className={location?.pathname === "/"+authUser?.role+"/templates-add" ? "nav-item nav-link active" : "nav-item nav-link"} to={"/"+authUser?.role+"/tempates-add"} >Templates-Add</Link> */}

                    <Link className={location?.pathname === "/" + authUser?.role + "/inspections" ? "nav-item nav-link active" : "nav-item nav-link"} to={"/" + authUser?.role + "/inspections"} onClick={scrollToTop}><span data-bs-toggle="collapse" data-bs-target="#navbarCollapse">Inspections</span></Link>

                    {authUser?.role !== 'admin' && <Link className={location?.pathname === "/pricing" ? "nav-item nav-link active" : "nav-item nav-link"} to={"/" + authUser?.role + "/pricing"}  onClick={scrollToTop}><span data-bs-toggle="collapse" data-bs-target="#navbarCollapse">Pricing</span></Link>}

                    {authUser?.role === 'admin' ? <>
                      <Link className={location?.pathname === "/" + authUser?.role + "/commercials" ? "nav-item nav-link active" : "nav-item nav-link"} to={"/" + authUser?.role + "/commercials"} onClick={scrollToTop}><span data-bs-toggle="collapse" data-bs-target="#navbarCollapse">Paid Users</span></Link>
                      <Link className={location?.pathname === "/" + authUser?.role + "/residentials" ? "nav-item nav-link active" : "nav-item nav-link"} to={"/" + authUser?.role + "/residentials"} onClick={scrollToTop}><span data-bs-toggle="collapse" data-bs-target="#navbarCollapse">Free Users</span></Link>
                      <Link className={location?.pathname === "/" + authUser?.role + "/orders" ? "nav-item nav-link active" : "nav-item nav-link"} to={"/" + authUser?.role + "/orders"} onClick={scrollToTop}><span data-bs-toggle="collapse" data-bs-target="#navbarCollapse">Transactions</span></Link>
                      {/* <Link className={location?.pathname === "/" + authUser?.role + "/subscriptions" ? "nav-item nav-link active" : "nav-item nav-link"} to={"/" + authUser?.role + "/subscriptions"}>Subscriptions</Link>
                      <Link className={location?.pathname === "/" + authUser?.role + "/ended-subscriptions" ? "nav-item nav-link active" : "nav-item nav-link"} to={"/" + authUser?.role + "/ended-subscriptions"}>Ended Subscriptions</Link>
                       */}
                    </> : <></>}
                    {((authUser?.role === 'commercial')) ? <>
                      <Link className={location?.pathname === "/" + authUser?.role + "/payments" ? "nav-item nav-link active" : "nav-item nav-link"} to={"/" + authUser?.role + "/payments"} onClick={scrollToTop}>Payments</Link>
                    </> : <></>}
                    <Dropdown className="d-inline nav-item nav-link">
                      {authUser?.role === 'admin' ? <Dropdown.Toggle id="dropdown-autoclose-true" as="span" className="click">
                        <>{authUser?.firstname}</>
                      </Dropdown.Toggle> : <Dropdown.Toggle id="dropdown-autoclose-true" as="span" className="click ">
                        {/* <img src={process.REACT_APP_IMG_URL + authUser?.image} className="avatar me-2" />  */}
                        <>{authUser?.firstname}</>
                        {/*  [ {authUser?.role === 'residential' ? 'Estudiante' : 'Profesor'} ] */}
                      </Dropdown.Toggle>}

                      <Dropdown.Menu className="mt-2">
                        <Dropdown.Item ><Link className="nav-link w-100 py-1 px-0" to={"/" + authUser?.role + "/profile"}>Profile</Link></Dropdown.Item>

                        <Dropdown.Item ><Link className="nav-link w-100 py-1 px-0" onClick={signout}>Log Out</Link></Dropdown.Item>
                      </Dropdown.Menu>

                      {/* {authUser?.role === 'commercial' && <Dropdown.Menu className="mt-2">
                    <Dropdown.Item ><Link className="nav-link w-100" to="/commercial/profile">Perfil</Link></Dropdown.Item>
                    <Dropdown.Item ><Link className="nav-link w-100" to="/commercial/classes">Clases</Link></Dropdown.Item>
                    <Dropdown.Item ><Link className="nav-link w-100" to="/commercial/courses">Cursos</Link></Dropdown.Item>
                    <Dropdown.Item ><Link className="nav-link w-100" to="/commercial/how-does-it-works">Cómo funciona</Link></Dropdown.Item>
                    <Dropdown.Item ><Link className="nav-link w-100" to="commercial/payments">Pagos</Link></Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item ><Link className="nav-link w-100" onClick={signout}>Cerrar sesión</Link></Dropdown.Item>
                  </Dropdown.Menu>}

                  {authUser?.role === 'residential' && <Dropdown.Menu className="mt-2">
                    <Dropdown.Item ><Link className="nav-link w-100" to={"/"+authUser?.role+"/profile"}>Perfil</Link></Dropdown.Item>
                    <Dropdown.Item ><Link className="nav-link w-100" to={"/"+authUser?.role+"/subscriptions"}>Clases registradas</Link></Dropdown.Item>
                    <Dropdown.Item ><Link className="nav-link w-100" to={"/"+authUser?.role+"/ended-subscriptions"}>Clases finalizadas</Link></Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item ><Link className="nav-link w-100" onClick={signout}>Cerrar sesión</Link></Dropdown.Item>
                  </Dropdown.Menu>}

                  {authUser?.role === 'admin' && <Dropdown.Menu className="mt-2">
                    <Dropdown.Item ><Link className="nav-link w-100" to="/admin/commercials">Profesores</Link></Dropdown.Item>
                    <Dropdown.Item ><Link className="nav-link w-100" to="/admin/residentials">Alumnos</Link></Dropdown.Item>
                    <Dropdown.Item ><Link className="nav-link w-100" to="/admin/classes">Clases</Link></Dropdown.Item>
                    <Dropdown.Item ><Link className="nav-link w-100" to="/admin/orders">Datos financieros</Link></Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item ><Link className="nav-link w-100" onClick={signout}>Cerrar sesión</Link></Dropdown.Item>
                  </Dropdown.Menu>} */}


                    </Dropdown>
                  </>

                }




              </div>

            </div>
          </nav>
        </div>
      </div>




      <Modal
        show={props.showLogin}
        onHide={loginHide}
        backdrop="static"
        keyboard={false}
        centered
      >

        <Modal.Body className="py-4 text-center">
          {!isresetpwd ?
            <>
              <div className="row">
                <div className="col-lg-12 col-12">
                  <Link className="float-end" onClick={loginHide}><IoCloseOutline size={25} /></Link>
                </div>
                <div className="col-lg-12 col-12 mb-4">
                  <h5 className="text-green">Login</h5>
                </div>
              </div>
              <form onSubmit={(e) => { e.preventDefault(); handleLogin() }}>
                <input type="email" onChange={e => setEmailValue(e.target.value)} className="form-control border-green rounded-0 mb-4" placeholder="Email" required />
                <input type="password" onChange={e => setPasswordValue(e.target.value)} className="form-control border-green rounded-0 mb-1" placeholder="Password" required />
                <small className="text-danger">{errorMessage}</small>

                <Link onClick={() => { setIsresetpwd(true) }} className="small float-end">Forgot password?</Link>
                <br />

                <button type="submit" className="btn btn-lg btn-primary px-5 mb-4">Login</button>


                {/* <div className="mt-4">
                  <SocialButton
                    provider="facebook"
                    //appId="859529925371636"
                    appId="1889722324710238"
                    onLoginSuccess={handleSocialLogin}
                    onLoginFailure={handleSocialLoginFailure}
                    className="btn bg-fb btn-primary rounded-5 px-4">
                    <IoLogoFacebook /> &nbsp;Iniciar sesión con Facebook

                  </SocialButton>
                </div>

                <div className="mb-4 text-center">
                  <button type="button" className="btn btn-outline-secondary rounded-5 px-4"
                    onClick={() => googlelogin()}
                  ><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M473.16 221.48l-2.26-9.59H262.46v88.22H387c-12.93 61.4-72.93 93.72-121.94 93.72-35.66 0-73.25-15-98.13-39.11a140.08 140.08 0 01-41.8-98.88c0-37.16 16.7-74.33 41-98.78s61-38.13 97.49-38.13c41.79 0 71.74 22.19 82.94 32.31l62.69-62.36C390.86 72.72 340.34 32 261.6 32c-60.75 0-119 23.27-161.58 65.71C58 139.5 36.25 199.93 36.25 256s20.58 113.48 61.3 155.6c43.51 44.92 105.13 68.4 168.58 68.4 57.73 0 112.45-22.62 151.45-63.66 38.34-40.4 58.17-96.3 58.17-154.9 0-24.67-2.48-39.32-2.59-39.96z"></path></svg> &nbsp;Iniciar sesión con Google &nbsp; &nbsp;</button>
                  

                </div> */}


              </form>
              <div >
                Don't have an account? <Link className="fw-bold text-black" onClick={() => { loginHide(); registrationShow() }}>Register Now</Link>
              </div></> :
            <>
              <div className="row">
                <div className="col-lg-12 col-12">
                  <Link className="float-end" onClick={loginHide}><IoCloseOutline size={25} /></Link>
                </div>
                <div className="col-lg-12 col-12 mb-4">
                  <h5 className="text-green">Reset your password</h5>
                </div>
              </div>
              <form onSubmit={(e) => { e.preventDefault(); sendResetPwdLink() }}>
                <input type="email" onChange={e => setEmailValue(e.target.value)} className="form-control border-green rounded-0 mb-4" placeholder="Email" required />
                <button type="submit" className="btn btn-lg btn-primary px-5 mb-4">Send Reset Link</button>
              </form>
            </>
          }

        </Modal.Body>

      </Modal>

      <Modal
        show={showRegistration}
        onHide={registrationHide}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >

        <Modal.Body className="py-4 text-center">
          <div className="row">
            <div className="col-lg-12 col-12">
              <Link className="float-end" onClick={registrationHide}><IoCloseOutline size={25} /></Link>
            </div>
            <div className="col-lg-12 col-12 mb-4">
              <h5 className="text-green">Join The Waiting List</h5>
            </div>
          </div>

          {/* <div className="row mb-3">
            <div className="col">
              <button
                className={`btn rounded-5 w-100 ${role === 'residential' ? "bg-green" : "border-green"}`}
                onClick={() => { setRole('residential'); setApproved(true) }}
              >Estudiante</button>
            </div>
            <div className="col">
              <button
                className={`btn rounded-5 w-100 ${role === 'commercial' ? "bg-green" : "border-green"}`}
                onClick={() => { setRole('commercial'); setApproved(false) }}
              >Profesor</button>
            </div>
          </div> */}
          <form onSubmit={(e) => { e.preventDefault(); register() }}>
            <input onChange={e => setFirstname(e.currentTarget.value)} type="text" className="form-control border-green rounded-0 mb-3" placeholder="First Name" required />
            <input onChange={e => setLastname(e.currentTarget.value)} type="text" className="form-control border-green rounded-0 mb-3" placeholder="Last Name" required />
            <input onChange={e => setEmail(e.currentTarget.value)} size="30" type="email" className="form-control border-green rounded-0 mb-3" placeholder="Email" pattern="(?![_.-])((?![_.-][_.-])[a-zA-Z\d_.-]){0,63}[a-zA-Z\d]@((?!-)((?!--)[a-zA-Z\d-]){0,63}[a-zA-Z\d]\.){1,2}([a-zA-Z]{2,14}\.)?[a-zA-Z]{2,14}" required />
            {/* <input type="text" max={new Date().toISOString().split("T")[0]}
              onFocus={(e) => (e.target.type = "date")} onBlur={(e) => (e.target.type = "text")} onChange={e => setBirthday(e.currentTarget.value)} className="form-control border-green rounded-0 mb-3" placeholder="Fecha de nacimiento (DD/MM/AAAA)" /> */}

            {/* <select onChange={e => setAddress(e.currentTarget.value)} value={address} className="form-select border-green rounded-0 mb-3">
              <option disabled selected value="">Country</option>
              {countries.map(e =>
                <option key={e.code} value={e.code}>{e.name}</option>
              )};
            </select> */}
   {/* <label class="form-label">What fault do you most see on the solar arrays while cleaning?</label> */}

<input onChange={e => setWhichIssueFoundInOldSystem(e.currentTarget.value)} type="text" className="form-control border-green rounded-0 mb-3" placeholder="What fault do you most see on the solar arrays while cleaning?" required />

{/* <label className="form-label text-green">What Feature On A Solar Panel Cleaning Report Would Appeal To You Most?</label> */}
<textarea onChange={e => setWhichFeatureAppealsInOldSystem(e.currentTarget.value)} type="text" className="form-control border-green rounded-0 mb-3" placeholder="What Feature On A Solar Panel Cleaning Report Would Appeal To You Most?" required />

            {/* {role === 'commercial' &&
              <div className="text-start">
                <input onChange={e => setSubjects(e.currentTarget.value)} type="text" className="form-control border-green rounded-0 mb-3" placeholder="¿De qué materias quieres dar clases?" required />

                <label className="form-label text-green">Cuéntanos sobre tu experiencia profesional</label>
                <textarea onChange={e => setExperience(e.currentTarget.value)} type="text" className="form-control border-green rounded-0 mb-3" placeholder="Máximo 100 palabras" required />
              </div>
            } */}
            <input onChange={e => setPassword(e.currentTarget.value)} type="password" minLength={6} className="form-control border-green rounded-0 mb-3" placeholder="Password" required />
            <input onChange={e => setConfirmpassword(e.currentTarget.value)} type="password" minLength={6} className="form-control border-green rounded-0 mb-3" placeholder="Confirm Password" pattern={"^" + password} required />
            <small></small>


            <Form.Check type="checkbox" className='mb-3 text-start'>
              <Form.Check.Input type="checkbox" onChange={(e) => setTermscheckbox(e.target.checked)} />
              <Form.Check.Label className='small'>I agree to Solarscope’s <Link to="/conditions" className='small' target={'_blank'}>Terms & Conditions</Link> & <Link to="/privacy-policies" className='small' target={'_blank'}>Privacy Policy</Link> {termscheckbox}</Form.Check.Label>

            </Form.Check>

            {/* <div className="text-center">
              <Reaptcha
                style={{ display: "inline-block" }}
                sitekey="6LcPOTQjAAAAAGP-GIS0DUpa6J3_O8nzcjejbjtu"
                onVerify={onChangeCaptcha}

              />
            </div> */}


            <button type="submit" className="btn btn-primary px-5 mb-2">
              {loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>} Join Now</button>
          </form>
          <div className="mb-3">
            Already have an approved account? <Link onClick={() => { registrationHide(); loginShow() }} className="fw-bold text-black">Login</Link>
          </div>

          {/* {role === 'residential' && <>
            <div className="mb-3">
              <Link className="fw-bold text-black">o iniciar con:</Link>
            </div>
            <div className="mt-2">
              <SocialButton
                provider="facebook"
                //appId="859529925371636"
                appId="1889722324710238"
                onLoginSuccess={handleSocialRegister}
                onLoginFailure={handleSocialLoginFailure}
                className="btn bg-fb btn-primary rounded-5 px-4">
                <IoLogoFacebook /> &nbsp;Iniciar sesión con Facebook

              </SocialButton>
            </div>

            <div className="mb-2">
              <SocialButton
                provider="google"
                //appId="28922898118-qb76og13tic79p06r09pjqbvdma8kh7r"
                appId="126701771732-fmve92dmj5m2heh8u90iukn23bekcl6a"
                onLoginSuccess={handleSocialRegister}
                onLoginFailure={handleSocialLoginFailure}
                className="btn btn-outline-secondary rounded-5 px-4">
                <IoLogoGoogle /> &nbsp;Iniciar sesión con Google &nbsp; &nbsp;

              </SocialButton>

            </div>
          </>} */}



        </Modal.Body>

      </Modal>

    </>
  );
}


const mapStateToProps = state => ({
  showLogin: state?.user?.showLogin,
})

const mapActionToProps = {

  updateUser: user.update,
  invokesignin: user.invokesignin

}

export default connect(mapStateToProps, mapActionToProps)(Header);